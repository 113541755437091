import {Dialog} from "@headlessui/react"
import {cloneElement, useMemo} from "react"

import {useToggle} from "~/hooks/use-toggle"

type ModalProps = {
  children: React.ReactNode | ((onClose: () => void) => React.ReactNode)
  activator?: React.ReactElement
  active?: boolean
  onClose?: () => void
}

export const Modal = ({children, activator, active, onClose}: ModalProps) => {
  const [isOpen, toggleIsOpen] = useToggle()

  const activatorMarkup = useMemo(
    () =>
      activator
        ? cloneElement(activator, {
            onClick: toggleIsOpen,
          })
        : null,
    [activator, toggleIsOpen],
  )

  return (
    <>
      {activatorMarkup}
      <Dialog
        open={active ?? isOpen}
        onClose={onClose ?? toggleIsOpen}
        className="relative z-50"
      >
        <div className="fixed inset-0 bg-spacemist/80 overflow-y-auto" />

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-1">
            <Dialog.Panel as="div">
              {typeof children === "function"
                ? children(onClose ?? toggleIsOpen)
                : children}
            </Dialog.Panel>
          </div>
        </div>
      </Dialog>
    </>
  )
}
